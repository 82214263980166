/*
 * This Components gets ALL Registerd users from the PHP server and tbl_get_all_grads
 * order by last name
 * 
 * Called by:
 *    <EditAProfile /> Page
 *    <SuperUserAddABio /> Page
 *    <SuperUserAddAStory /> Page
 */
import React, { useEffect } from 'react'
import axios from 'axios'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import PropTypes from 'prop-types'

const AllGrads = ({getAllGrads}) => {

   useEffect(() => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + 'grad/getAllRegisteredGrads')
         .then(res => res.data)
         .then(data => {
            getAllGrads(data)
         })
   }, [getAllGrads])

   return (
      <div>
      
      </div>
   )
}

AllGrads.propTypes = {
  getAllGrads: PropTypes.func
}

export default AllGrads
