/*
 * This is for alumni members to update their profile
 * This is not superUser updage
 */
import React, { useEffect, useState } from 'react'
import "./AddGrad.css"
import axios from "axios"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import Grid from "@material-ui/core/Grid"
import GradCard from "../Components/GradCard"
import MySelect from "../Components/MySelect"
import { Redirect } from "react-router-dom"
import Snackbar from "../Atoms/Snackbar"
import { store } from "../Redux/Store"
import TextField from "@material-ui/core/TextField"
import { userIdAction } from "../Redux/Actions"
import { milRankOptions, milStatusOptions, trackOptions } from "../config"

const UpdateGrad = () => {

    const { isActive, signedInUserId } = store.getState()

    const [ address, setAddress ] = useState('')
    const [ astronaut, setAstronaut ] = useState(false)
    const [ callsign, setCallsign ] = useState('')
    const [ cDot, setCDot ] = useState(false)
    const [ city, setCity ] = useState('')
    const [ commandant, setCommandant ] = useState(false)
    const [ country, setCountry ] = useState('')
    const [ currentCompany, setCurrentCompany ] = useState('')
    const [ currentJobTitle, setCurrentJobTitle ] = useState('')
    const [ distinguishedAlumnus, setDistinguishedAlumnus ] = useState(false)
    const [ educationBS, setEducationBS ] = useState('')
    const [ educationMS, setEducationMS ] = useState('')
    const [ educationPHD, setEducationPHD ] = useState('')
    const [ generalOfficer, setGeneralOfficer ] = useState(false)
    const [ givenFirstName, setGivenFirstName ] = useState('')
    const [ gradCardOpen, setGradCardOpen ] = useState(false)
    const [ gradNumber, setGradNumber ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ liethenTittle, setLiethenTittle ] = useState(false)
    const [ militaryService, setMilitaryService ] = useState('')
    const [ userStatus, setUserStatus ] = useState('')
    const [ newUserIsAdmin, setNewUserIsAdmin ] = useState(false)
    const [ newUserIsAlumni, setNewUserIsAlumni ] = useState(false)
    const [ newUserIsSuperUser, setNewUserIsSuperUser ] = useState(false)
    const [ password, setPassword ] = useState('')
    const [ personalCell, setPersonalCell ] = useState('')
    const [ personalEmail, setPersonalEmail ] = useState('')
    const [ preferredFirstName, setPreferredFirstName ] = useState('')
    const [ propWash, setPropWash ] = useState(false)
    const [ militaryRank, setMilitaryRank ] = useState('')
    const [ raymondJones, setRaymondJones ] = useState(false)
    const [ showOneGrad, setShowOneGrad ] = useState(false)
    const [ snackBarOpen, setSnackBarOpen ] = useState(false)
    const [ state, setState ] = useState('')
    const [ suffix, setSuffix ] = useState('')
    const [ tpsClass, setTpsClass ] = useState('')
    const [ track, setTrack ] = useState('')
    const [ username, setUsername ] = useState('')
    const [ workEmail, setWorkEmail ] = useState('')
    const [ workPhone, setWorkPhone ] = useState('')
    const [ zip, setZip ] = useState('')

    const handleAddressChange = (e) => { setAddress(e.target.value) }
   //  const handleAstronautChange = () => { setAstronaut(!astronaut) }
    const handleCallsignChange = (e) => { setCallsign(e.target.value) }
   //  const handleCDotChange = () => { setCDot(!cDot) }
    const handleCityChange = (e) => { setCity(e.target.value) }
   //  const handleCommandantChange = () => { setCommandant(!commandant) }
    const handleCountryChange = (e) => { setCountry(e.target.value) }
    const handleCurrentCompanyChange = (e) => { setCurrentCompany(e.target.value) }
    const handleCurrentJobTitleChange = (e) => { setCurrentJobTitle(e.target.value) }
   //  const handleDistinguishedAlumnusChange = () => { setDistinguishedAlumnus(!distinguishedAlumnus) }
    const handleEducationBSChange = (e) => { setEducationBS(e.target.value) }
    const handleEducationMSChange = (e) => { setEducationMS(e.target.value) }
    const handleEducationPHDChange = (e) => { setEducationPHD(e.target.value) }
   //  const handleGeneralOfficerChange = () => { setGeneralOfficer(!generalOfficer) }
    const handleGivenFirstNameChange = (e) => { setGivenFirstName(e.target.value) }
    const handleLastNameChange = (e) => { setLastName(e.target.value) }
   //  const handleLiethenTittleChange = () => { setLiethenTittle(!liethenTittle) }
    const handleMilitaryServiceChange = (e) => { setMilitaryService(e.target.value) }
    const handleUserStatusChange = (e) => { setUserStatus(e.target.value) }
    const handleNewUserIsAdminChange = () => { setNewUserIsAdmin(!newUserIsAdmin) }
    const handleNewUserIsAlumniChange = () => { setNewUserIsAlumni(!newUserIsAlumni) }
    const handleNewUserIsSuperUserChange = () => { setNewUserIsSuperUser(!newUserIsSuperUser) }
    const handlePasswordChange = (e) => { setPassword(e.target.value) }
    const handlePersonalCellChange = (e) => { setPersonalCell(e.target.value) }
    const handlePersonalEmailChange = (e) => { setPersonalEmail(e.target.value) }
    const handlePreferredFirstNameChange = (e) => { setPreferredFirstName(e.target.value) }
   //  const handlePropWashChange = () => { setPropWash(!propWash) }
    const handleMilitaryRankChange = (e) => { setMilitaryRank(e.target.value) }
   //  const handleRaymondJonesChange = () => { setRaymondJones(!raymondJones) }
    const handleStateChange = (e) => { setState(e.target.value) }
    const handleSuffixChange = (e) => { setSuffix(e.target.value) }
    const handleTpsClassChange = (e) => { setTpsClass(e.target.value) }
    const handleTrackChange = (e) => { setTrack(e.target.value) }
    const handleUsernameChange = (e) => { setUsername(e.target.value) }
    const handleWorkEmailChange = (e) => { setWorkEmail(e.target.value) }
    const handleWorkPhoneChange = (e) => { setWorkPhone(e.target.value) }
    const handleZipChange = (e) => { setZip(e.target.value) }

    useEffect( () => {
        let userData = new FormData()
        userData.append('signedInUserId', signedInUserId)

        axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
        axios.get(node_server + `alumni/getSingleUser/${signedInUserId}`)
            .then( res => res.data )
            .then( data => {
                setAddress(data.address)
                setAstronaut(data.astronaut)
                setCallsign(data.callsign)
                setCDot(data.cDot)
                setCity(data.city)
                setCommandant(data.commandant)
                setCountry(data.country)
                setCurrentCompany(data.currentCompany)
                setCurrentJobTitle(data.currentJobTitle)
                setDistinguishedAlumnus(data.distinguishedAlumnus)
                setEducationBS(data.educationBS)
                setEducationMS(data.educationMS)
                setEducationPHD(data.educationPHD)
                setGeneralOfficer(data.generalOfficer)
                setGivenFirstName(data.givenFirstName)
                setGradCardOpen(data.gradCardOpen)
                setGradNumber(data.gradNumber)
                setLastName(data.lastName)
                setLiethenTittle(data.liethenTittle)
                setMilitaryService(data.militaryService)
                setUserStatus(data.userStatus)
                setNewUserIsAdmin(data.newUserIsAdmin)
                setNewUserIsAlumni(data.newUserIsAlumni)
                setNewUserIsSuperUser(data.newUserIsSuperUser)
                setPersonalCell(data.personalCell)
                setPersonalEmail(data.personalEmail)
                setPreferredFirstName(data.preferredFirstName)
                setPropWash(data.propWash)
                setMilitaryRank(data.militaryRank)
                setRaymondJones(data.raymondJones)
                setSnackBarOpen(data.snackBarOpen)
                setState(data.state)
                setSuffix(data.suffix)
                setTpsClass(data.tpsClass)
                setTrack(data.track)
                setUsername(data.username)
                setWorkEmail(data.workEmail)
                setWorkPhone(data.workPhone)
                setZip(data.zip)
            })

    }, [tpsClass, signedInUserId] )


    const handleSubmit = (e) => {
        e.preventDefault()

        var updateGradFormData = new FormData()

        updateGradFormData.append("address", address)
        updateGradFormData.append("admin", admin)
        updateGradFormData.append("alumni", alumni)
        updateGradFormData.append("astronaut", astronaut)
        updateGradFormData.append("callsign", callsign)
        updateGradFormData.append("cDot", cDot)
        updateGradFormData.append("city", city)
        updateGradFormData.append("commandant", commandant)

        if(country === 'US'){ updateGradFormData.append("country", "USA") }
        else if(country === 'us'){ updateGradFormData.append("country", "USA") }
        else if(country === 'usa'){ updateGradFormData.append("country", "USA") }
        else{ updateGradFormData.append("country", country) }

        updateGradFormData.append("currentCompany", currentCompany)
        updateGradFormData.append("currentJobTitle", currentJobTitle)
        updateGradFormData.append("distinguishedAlumnus", distinguishedAlumnus)

        updateGradFormData.append("educationBS", educationBS)
        updateGradFormData.append("educationMS", educationMS)
        updateGradFormData.append("educationPHD", educationPHD)

        updateGradFormData.append("generalOfficer", generalOfficer)
        updateGradFormData.append("givenFirstName", givenFirstName)
        updateGradFormData.append("gradNumber", gradNumber)
        updateGradFormData.append("isActive", isActive)
        updateGradFormData.append("lastName", lastName)
        updateGradFormData.append("liethenTittle", liethenTittle)
        updateGradFormData.append("militaryService", militaryService)
        updateGradFormData.append("newUserIsAdmin", newUserIsAdmin)
        updateGradFormData.append("newUserIsAlumni", newUserIsAlumni)
        updateGradFormData.append("newUserIsSuperUser", newUserIsSuperUser)
        updateGradFormData.append("password", password)
        updateGradFormData.append("personalCell", personalCell)
        updateGradFormData.append("personalEmail", personalEmail)
        updateGradFormData.append("preferredFirstName", preferredFirstName)
        updateGradFormData.append("propWash", propWash)
        updateGradFormData.append("militaryRank", militaryRank)
        updateGradFormData.append("raymondJones", raymondJones)
        updateGradFormData.append("signedInUserId", signedInUserId)
        updateGradFormData.append("state", state)
        updateGradFormData.append("suffix", suffix)
        updateGradFormData.append("superUser", superUser)
        updateGradFormData.append("track", track)
        updateGradFormData.append("tpsClass", tpsClass)
        updateGradFormData.append("username", username)
        updateGradFormData.append("userStatus", userStatus)
        updateGradFormData.append("workEmail", workEmail)
        updateGradFormData.append("workPhone", workPhone)
        updateGradFormData.append("zip", zip)

        const data = {
         address,
         admin,
         alumni,
         callsign,
         city,
         country,
         currentCompany,
         currentJobTitle,
         educationBS,
         educationMS,
         educationPHD,
         givenFirstName,
         gradNumber,
         isActive,
         lastName,
         militaryService,
         newUserIsAdmin,
         newUserIsAlumni,
         newUserIsSuperUser,
         password,
         personalCell,
         personalEmail,
         preferredFirstName,
         militaryRank,
         signedInUserId,
         state,
         suffix,
         superUser,
         track,
         tpsClass,
         username,
         userStatus,
         workEmail,
         workPhone,
         zip,
      }

      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.post(node_server + 'updateGrad', data)
         .then( res => res.data )
         .then( data => {
               store.dispatch(userIdAction(signedInUserId))
               setShowOneGrad(true)
         } )

    }


    const { admin, alumni, superUser } = store.getState()

    return (
        <Box style={{marginTop: '4rem'}}>
            {showOneGrad ? <Redirect to="/ShowOneGrad" /> : null}
            <Grid container justify="center">
                <h1>Update Your Profile</h1>
                {
                    gradCardOpen ?
                        <GradCard
                            givenFirstName={givenFirstName}
                            lastName={lastName}
                            militaryRank={militaryRank}
                            track={track}
                            tpsClass={tpsClass}
                        />
                        :
                        null
                }
                <form className="AddGrad-form">

                    {/* Displays only for a superUser */}
                    {superUser ?
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsSuperUser" label="Super User" options={["Yes", "No"]} onChange={handleNewUserIsSuperUserChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsAdmin" label="Admin" options={["Yes", "No"]} onChange={handleNewUserIsAdminChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsAlumni" label="Alumni" options={["Yes", "No"]} onChange={handleNewUserIsAlumniChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                    }

                    {/* Displays only for an admin */}
                    {admin ?
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsAdmin" label="Admin" options={["Yes", "No"]} onChange={handleNewUserIsAdminChange} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MySelect name="newUserIsAlumni" label="Alumni" options={["Yes", "No"]} onChange={handleNewUserIsAlumniChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                    }

                    {superUser || admin ?
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Username" name="username" onChange={handleUsernameChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Password" name="password" type="password" onChange={handlePasswordChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Re-enter Password" name="password1" type="password" />
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                    }

                    {/* Alumni see what is below, but not what is above */}
                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField autoFocus={true} className="AddGrad-text-field" label="Callsign" name="callsign" value={callsign} onChange={handleCallsignChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="TPS Class (ex. 2002a)" name="tpsClass" value={tpsClass} onChange={handleTpsClassChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Grad Number" name="gradNumber" value={gradNumber} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    <Grid item md={12}>
                        <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Given First Name" name="givenFirstName" value={givenFirstName} onChange={handleGivenFirstNameChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Preferred First Name" name="preferredFirstName" value={preferredFirstName} onChange={handlePreferredFirstNameChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Last Name" name="lastName" value={lastName} onChange={handleLastNameChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Suffix" name="suffix" value={suffix} onChange={handleSuffixChange} />
                            </Grid>
                        </Grid>
                    </Grid>

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Address" name="address" value={address} onChange={handleAddressChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="City" name="city" value={city} onChange={handleCityChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="State (2 digits)" name="state" value={state} onChange={handleStateChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Zip" name="zip" value={zip} onChange={handleZipChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Personal Cell" name="personalCell" value={personalCell} onChange={handlePersonalCellChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Personal E-mail" name="personalEmail" value={personalEmail} onChange={handlePersonalEmailChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Work Phone" name="workPhone" value={workPhone} onChange={handleWorkPhoneChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Work E-mail" name="workEmail" value={workEmail} onChange={handleWorkEmailChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Current Job Title" name="currentJobTitle" value={currentJobTitle} onChange={handleCurrentJobTitleChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Current Company" name="currentCompany" value={currentCompany} onChange={handleCurrentCompanyChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Country" name="country" value={country} onChange={handleCountryChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Military Service" name="militaryService" value={militaryService} onChange={handleMilitaryServiceChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="School Name - Education BS" name="educationBS" value={educationBS} onChange={handleEducationBSChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="School Name - Education MS" name="educationMS" value={educationMS} onChange={handleEducationMSChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="School Name - Education PhD" name="educationPHD" value={educationPHD} onChange={handleEducationPHDChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    {militaryRank === "" ?
                                    <MySelect name="militaryRank" label="Rank" options={milRankOptions} onChange={handleMilitaryRankChange} /> :
                                    <MySelect name="militaryRank" label={militaryRank} options={milRankOptions} onChange={handleMilitaryRankChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {track === "" ?
                                    <MySelect name="track" label="Student Track" options={trackOptions} onChange={handleTrackChange} /> :
                                    <MySelect name="track" label={track} options={trackOptions} onChange={handleTrackChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {userStatus === "" ?
                                    <MySelect name="userStatus" label="Military Status" options={milStatusOptions} onChange={handleUserStatusChange} /> :
                                    <MySelect name="userStatus" label={userStatus} options={milStatusOptions} onChange={handleUserStatusChange} />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {/* {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    {cDot ?
                                    <MySelect name="cDot" label="Yes" options={["Yes", "No"]} onChange={handleCDotChange} /> :
                                    <MySelect name="cDot" label="C-Dot" options={["Yes", "No"]} onChange={handleCDotChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {liethenTittle ?
                                    <MySelect name="liethenTittle" label="Yes" options={["Yes", "No"]} onChange={handleLiethenTittleChange} /> :
                                    <MySelect name="liethenTittle" label="Liethen-Tittle" options={["Yes", "No"]} onChange={handleLiethenTittleChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {propWash ?
                                    <MySelect name="propWash" label="Yes" options={["Yes", "No"]} onChange={handlePropWashChange} /> :
                                    <MySelect name="propWash" label="Prop Wash" options={["Yes", "No"]} onChange={handlePropWashChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {raymondJones ?
                                    <MySelect name="raymondJones" label="Yes" options={["Yes", "No"]} onChange={handleRaymondJonesChange} /> :
                                    <MySelect name="raymondJones" label="Raymond Jones" options={["Yes", "No"]} onChange={handleRaymondJonesChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {distinguishedAlumnus ?
                                    <MySelect name="distinguishedAlumnus" label="Yes" options={["Yes", "No"]} onChange={handleDistinguishedAlumnusChange} /> :
                                    <MySelect name="distinguishedAlumnus" label="Disting'd Alum" options={["Yes", "No"]} onChange={handleDistinguishedAlumnusChange} />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    } */}

                    {/* {newUserIsSuperUser || newUserIsAdmin
                        ? null :
                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    {commandant ?
                                    <MySelect name="commandant" label="Yes" options={["Yes", "No"]} onChange={handleCommandantChange} /> :
                                    <MySelect name="commandant" label="Commandant" options={["Yes", "No"]} onChange={handleCommandantChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {astronaut ?
                                    <MySelect name="astronaut" label="Yes" options={["Yes", "No"]} onChange={handleAstronautChange} /> :
                                    <MySelect name="astronaut" label="Astronaut" options={["Yes", "No"]} onChange={handleAstronautChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {generalOfficer ?
                                    <MySelect name="generalOfficer" label="Yes" options={["Yes", "No"]} onChange={handleGeneralOfficerChange} /> :
                                    <MySelect name="generalOfficer" label="General Officer" options={["Yes", "No"]} onChange={handleGeneralOfficerChange} />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    } */}

                    <Grid item md={12} style={{ marginLeft: 8 * 6, marginBottom: 8 * 2 }}>
                        <Grid container>
                            {snackBarOpen ?
                                <Snackbar type="error" msg="User not inserted" />
                                : null
                            }
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Box>
    )
}
export default UpdateGrad