/*
 * This is so a superUser can edit the profile of any grad
 * Need to get userId of Profile To Edit
 * <EditAProfile />
 *
 * Once a user is selected, that userId is sent to the stote and there is a redirect to
 * <EditOneProfilePage />
 */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import Grid from '@material-ui/core/Grid'
import MySelect from "../Components/MySelect"
import { Redirect } from 'react-router-dom'
import Snackbar from '../Atoms/Snackbar'
import { store } from '../Redux/Store'
import TextField from '@material-ui/core/TextField'
import { milRankOptions, milStatusOptions, trackOptions } from "../config"

const EditOneProfilePage = () => {

    const [ address, setAddress ] = useState('')
    const [ astronaut, setAstronaut ] = useState(false)
    const [ callsign, setCallsign ] = useState('')
    const [ cDot, setCDot ] = useState(false)
    const [ city, setCity ] = useState('')
    const [ commandant, setCommandant ] = useState(false)
    const [ country, setCountry ] = useState('')
    const [ currentCompany, setCurrentCompany ] = useState('')
    const [ currentJobTitle, setCurrentJobTitle ] = useState('')
    const [ distinguishedAlumnus, setDistinguishedAlumnus ] = useState(false)
    const [ educationBS, setEducationBS ] = useState('')
    const [ educationMS, setEducationMS ] = useState('')
    const [ educationPHD, setEducationPHD ] = useState('')
    const [ generalOfficer, setGeneralOfficer ] = useState(false)
    const [ gradNumber, setGradNumber ] = useState('')
    const [ givenFirstName, setGivenFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ liethenTittle, setLiethenTittle ] = useState(false)
    const [ militaryRank, setMilitaryRank ] = useState('')
    const [ militaryService, setMilitaryService ] = useState('')
    const [ personalCell, setPersonalCell ] = useState('')
    const [ personalEmail, setPersonalEmail ] = useState('')
    const [ preferredFirstName, setPreferredFirstName ] = useState('')
    const [ propWash, setPropWash ] = useState(false)
    const [ raymondJones, setRaymondJones ] = useState(false)
    const [ showOneGrad, setShowOneGrad ] = useState(false)
    const [ snackBarOpen, setSnackBarOpen ] = useState(false)
    const [ state, setState ] = useState('')
    const [ suffix, setSuffix ] = useState('')
    const [ tpsClass, setTpsClass ] = useState('')
    const [ track, setTrack ] = useState('')
    const [ userStatus, setUserStatus ] = useState('')
    const [ workEmail, setWorkEmail ] = useState('')
    const [ workPhone, setWorkPhone ] = useState('')
    const [ zip, setZip ] = useState('')

    const { userIdOfProfileToEdit } = store.getState()

    useEffect( () => {

      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + `alumni/getSingleUser/${userIdOfProfileToEdit}`)
         .then( res => res.data )
         .then( data => {
                  setAddress( data.address )
                  setAstronaut( data.astronaut )
                  setCallsign( data.callsign )
                  setCDot( data.cDot )
                  setCity( data.city )
                  setCommandant( data.commandant )
                  setCountry( data.country )
                  setCurrentCompany( data.currentCompany )
                  setCurrentJobTitle( data.currentJobTitle )
                  setDistinguishedAlumnus( data.distinguishedAlumnus )
                  setEducationBS( data.educationBS )
                  setEducationMS( data.educationMS )
                  setEducationPHD( data.educationPHD )
                  setGeneralOfficer( data.generalOfficer )
                  setGivenFirstName( data.givenFirstName )
                  setGradNumber( data.gradNumber )
                  setLastName( data.lastName )
                  setLiethenTittle( data.liethenTittle )
                  setMilitaryService( data.militaryService )
                  setUserStatus( data.userStatus )
                  setPersonalCell( data.personalCell )
                  setPersonalEmail( data.personalEmail )
                  setPreferredFirstName( data.preferredFirstName )
                  setPropWash( data.propWash )
                  setMilitaryRank( data.militaryRank )
                  setRaymondJones( data.raymondJones )
                  setSnackBarOpen( data.snackBarOpen )
                  setState( data.state )
                  setSuffix( data.suffix )
                  setTpsClass( data.tpsClass )
                  setTrack( data.track )
                  setWorkEmail( data.workEmail )
                  setWorkPhone( data.workPhone )
                  setZip( data.zip )
         })
    }, [userIdOfProfileToEdit] )

    const handleAddressChange = (e) => { setAddress(e.target.value) }
   //  const handleAstronautChange = () => { setAstronaut(!astronaut) }
    const handleCallsignChange = (e) => { setCallsign(e.target.value) }
   //  const handleCDotChange = () => { setCDot(!cDot) }
    const handleCityChange = (e) => { setCity(e.target.value) }
   //  const handleCommandantChange = () => { setCommandant(!commandant) }
    const handleCountryChange = (e) => { setCountry(e.target.value) }
    const handleCurrentCompanyChange = (e) => { setCurrentCompany(e.target.value) }
    const handleCurrentJobTitleChange = (e) => { setCurrentJobTitle(e.target.value) }
   //  const handleDistinguishedAlumnusChange = () => { setDistinguishedAlumnus(!distinguishedAlumnus) }
    const handleEducationBSChange = (e) => { setEducationBS(e.target.value) }
    const handleEducationMSChange = (e) => { setEducationMS(e.target.value) }
    const handleEducationPHDChange = (e) => { setEducationPHD(e.target.value) }
   //  const handleGeneralOfficerChange = () => { setGeneralOfficer(!generalOfficer) }
    const handleGivenFirstNameChange = (e) => { setGivenFirstName(e.target.value) }
    const handleGradNumberChange = (e) => { setGradNumber(e.target.value) }
    const handleLastNameChange = (e) => { setLastName(e.target.value) }
   //  const handleLiethenTittleChange = () => { setLiethenTittle(!liethenTittle) }
    const handleMilitaryServiceChange = (e) => { setMilitaryService(e.target.value) }
    const handleUserStatusChange = (e) => { setUserStatus(e.target.value) }
    const handlePersonalCellChange = (e) => { setPersonalCell(e.target.value) }
    const handlePersonalEmailChange = (e) => { setPersonalEmail(e.target.value) }
    const handlePreferredFirstNameChange = (e) => { setPreferredFirstName(e.target.value) }
   //  const handlePropWashChange = () => { setPropWash(!propWash) }
    const handleMilitaryRankChange = (e) => { setMilitaryRank(e.target.value) }
   //  const handleRaymondJonesChange = () => { setRaymondJones(!raymondJones) }
    const handleStateChange = (e) => { setState(e.target.value) }
    const handleSuffixChange = (e) => { setSuffix(e.target.value) }
    const handleTpsClassChange = (e) => { setTpsClass(e.target.value) }
    const handleTrackChange = (e) => { setTrack(e.target.value) }
    const handleWorkEmailChange = (e) => { setWorkEmail(e.target.value) }
    const handleWorkPhoneChange = (e) => { setWorkPhone(e.target.value) }
    const handleZipChange = (e) => { setZip(e.target.value) }

    const handleSubmit = (e) => {
        e.preventDefault()

        var updateGradFormData = new FormData()

        updateGradFormData.append("address", address)
        updateGradFormData.append("alumni", true)
        updateGradFormData.append("astronaut", astronaut)
        updateGradFormData.append("callsign", callsign)
        updateGradFormData.append("cDot", cDot)
        updateGradFormData.append("city", city)
        updateGradFormData.append("commandant", commandant)

        if(country === 'US'){ updateGradFormData.append("country", "USA") }
        else if(country === 'us'){ updateGradFormData.append("country", "USA") }
        else if(country === 'usa'){ updateGradFormData.append("country", "USA") }
        else{ updateGradFormData.append("country", country) }

        updateGradFormData.append("currentCompany", currentCompany)
        updateGradFormData.append("currentJobTitle", currentJobTitle)
        updateGradFormData.append("distinguishedAlumnus", distinguishedAlumnus)

        updateGradFormData.append("educationBS", educationBS)
        updateGradFormData.append("educationMS", educationMS)
        updateGradFormData.append("educationPHD", educationPHD)

        updateGradFormData.append("generalOfficer", generalOfficer)
        updateGradFormData.append("givenFirstName", givenFirstName)
        updateGradFormData.append("gradNumber", gradNumber)
        updateGradFormData.append("isActive", true)
        updateGradFormData.append("lastName", lastName)
        updateGradFormData.append("liethenTittle", liethenTittle)
        updateGradFormData.append("militaryService", militaryService)
        updateGradFormData.append("personalCell", personalCell)
        updateGradFormData.append("personalEmail", personalEmail)
        updateGradFormData.append("preferredFirstName", preferredFirstName)
        updateGradFormData.append("propWash", propWash)
        updateGradFormData.append("militaryRank", militaryRank)
        updateGradFormData.append("raymondJones", raymondJones)
        updateGradFormData.append("signedInUserId", userIdOfProfileToEdit)
        updateGradFormData.append("state", state)
        updateGradFormData.append("suffix", suffix)
        updateGradFormData.append("track", track)
        updateGradFormData.append("tpsClass", tpsClass)
        updateGradFormData.append("userStatus", userStatus)
        updateGradFormData.append("workEmail", workEmail)
        updateGradFormData.append("workPhone", workPhone)
        updateGradFormData.append("zip", zip)

        const data = {
         address,
         callsign,
         city,
         country,
         currentCompany,
         currentJobTitle,
         educationBS,
         educationMS,
         educationPHD,
         givenFirstName,
         gradNumber,
         lastName,
         militaryService,
         personalCell,
         personalEmail,
         preferredFirstName,
         militaryRank,
         signedInUserId: userIdOfProfileToEdit,
         state,
         suffix,
         track,
         tpsClass,
         userStatus,
         workEmail,
         workPhone,
         zip,
      }

      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.post(node_server + 'updateGrad', data)
         .then( res => res.data )
         .then( data => {
               setShowOneGrad(true)
         } )
    }

    return (
        <Box style={{marginTop: '4rem'}}>
            {showOneGrad ? <Redirect to="/ShowOneGrad" /> : null}
            <Grid container justify="center">
                <h1>Update a Profile (SuperUsers Only)</h1>
                <form className="AddGrad-form">

                    <Grid item md={12}>
                        <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Callsign" name="callsign" value={callsign} onChange={handleCallsignChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="TPS Class (ex. 2002a)" name="tpsClass" value={tpsClass} onChange={handleTpsClassChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Grad Number" name="gradNumber" value={gradNumber} onChange={handleGradNumberChange} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={12}>
                        <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Given First Name" name="givenFirstName" value={givenFirstName} onChange={handleGivenFirstNameChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Preferred First Name" name="preferredFirstName" value={preferredFirstName} onChange={handlePreferredFirstNameChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Last Name" name="lastName" value={lastName} onChange={handleLastNameChange} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Suffix" name="suffix" value={suffix} onChange={handleSuffixChange} />
                            </Grid>
                        </Grid>
                    </Grid>

                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Address" name="address" value={address} onChange={handleAddressChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="City" name="city" value={city} onChange={handleCityChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="State (2 digits)" name="state" value={state} onChange={handleStateChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Zip" name="zip" value={zip} onChange={handleZipChange} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Personal Cell" name="personalCell" value={personalCell} onChange={handlePersonalCellChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Personal E-mail" name="personalEmail" value={personalEmail} onChange={handlePersonalEmailChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Work Phone" name="workPhone" value={workPhone} onChange={handleWorkPhoneChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Work E-mail" name="workEmail" value={workEmail} onChange={handleWorkEmailChange} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Current Job Title" name="currentJobTitle" value={currentJobTitle} onChange={handleCurrentJobTitleChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Current Company" name="currentCompany" value={currentCompany} onChange={handleCurrentCompanyChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Country" name="country" value={country} onChange={handleCountryChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Military Service" name="militaryService" value={militaryService} onChange={handleMilitaryServiceChange} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={12}>
                            <Grid container direction="row" spacing={2} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="School Name - Education BS" name="educationBS" value={educationBS} onChange={handleEducationBSChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="School Name - Education MS" name="educationMS" value={educationMS} onChange={handleEducationMSChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="School Name - Education PhD" name="educationPHD" value={educationPHD} onChange={handleEducationPHDChange} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    {militaryRank === "" ?
                                    <MySelect name="militaryRank" label="Rank" options={milRankOptions} onChange={handleMilitaryRankChange} /> :
                                    <MySelect name="militaryRank" label={militaryRank} options={milRankOptions} onChange={handleMilitaryRankChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {track === "" ?
                                    <MySelect name="track" label="Student Track" options={trackOptions} onChange={handleTrackChange} /> :
                                    <MySelect name="track" label={track} options={trackOptions} onChange={handleTrackChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {userStatus === "" ?
                                    <MySelect name="userStatus" label="Military Status" options={milStatusOptions} onChange={handleUserStatusChange} /> :
                                    <MySelect name="userStatus" label={userStatus} options={milStatusOptions} onChange={handleUserStatusChange} />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    {cDot ?
                                    <MySelect name="cDot" label="Yes" options={["Yes", "No"]} onChange={handleCDotChange} /> :
                                    <MySelect name="cDot" label="C-Dot" options={["Yes", "No"]} onChange={handleCDotChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {liethenTittle ?
                                    <MySelect name="liethenTittle" label="Yes" options={["Yes", "No"]} onChange={handleLiethenTittleChange} /> :
                                    <MySelect name="liethenTittle" label="Liethen-Tittle" options={["Yes", "No"]} onChange={handleLiethenTittleChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {propWash ?
                                    <MySelect name="propWash" label="Yes" options={["Yes", "No"]} onChange={handlePropWashChange} /> :
                                    <MySelect name="propWash" label="Prop Wash" options={["Yes", "No"]} onChange={handlePropWashChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {raymondJones ?
                                    <MySelect name="raymondJones" label="Yes" options={["Yes", "No"]} onChange={handleRaymondJonesChange} /> :
                                    <MySelect name="raymondJones" label="Raymond Jones" options={["Yes", "No"]} onChange={handleRaymondJonesChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {distinguishedAlumnus ?
                                    <MySelect name="distinguishedAlumnus" label="Yes" options={["Yes", "No"]} onChange={handleDistinguishedAlumnusChange} /> :
                                    <MySelect name="distinguishedAlumnus" label="Disting'd Alum" options={["Yes", "No"]} onChange={handleDistinguishedAlumnusChange} />
                                    }
                                </Grid>
                            </Grid>
                        </Grid> */}

                        {/* <Grid item md={12}>
                            <Grid container direction="row" spacing={0} style={{ marginBottom: 15 }}>
                                <Grid item xs={12} md={2}>
                                    {commandant ?
                                    <MySelect name="commandant" label="Yes" options={["Yes", "No"]} onChange={handleCommandantChange} /> :
                                    <MySelect name="commandant" label="Commandant" options={["Yes", "No"]} onChange={handleCommandantChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {astronaut ?
                                    <MySelect name="astronaut" label="Yes" options={["Yes", "No"]} onChange={handleAstronautChange} /> :
                                    <MySelect name="astronaut" label="Astronaut" options={["Yes", "No"]} onChange={handleAstronautChange} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {generalOfficer ?
                                    <MySelect name="generalOfficer" label="Yes" options={["Yes", "No"]} onChange={handleGeneralOfficerChange} /> :
                                    <MySelect name="generalOfficer" label="General Officer" options={["Yes", "No"]} onChange={handleGeneralOfficerChange} />
                                    }
                                </Grid>
                            </Grid>
                        </Grid> */}

                    <Grid item md={12} style={{ marginLeft: 8 * 6, marginBottom: 8 * 2 }}>
                        <Grid container>
                            {snackBarOpen ?
                                <Snackbar type="error" msg="User not inserted" />
                                : null
                            }
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Box>
    )
}

export default EditOneProfilePage
