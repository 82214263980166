import React, { useEffect, useState } from 'react'
import axios from "axios"
import Box from "@material-ui/core/Box"
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { store } from "../Redux/Store"
import Typography from "@material-ui/core/Typography"

const ShowOneGrad = props => {

   const [data, setData] = useState('')
   
   useEffect(() => {
      let { userId, userIdOfProfileToEdit } = store.getState()
      let tempUserId

        if(userId > 9){
            tempUserId = userId
        }
        else{
            tempUserId = userIdOfProfileToEdit
        }
        axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
        axios.get(node_server + `alumni/getSingleUser/${tempUserId}`)
            .then(res => res.data)
            .then(data => {
                setData(data)
            })
    }, [])


    const { address, astronaut, callsign, cDot, city, commandant, country, currentCompany,
        currentJobTitle, distinguishedAlumnus, foreignOfficer, generalOfficer, givenFirstName, gradNumber,
        lastName, liethenTittle, militaryRank, militaryService, personalCell, personalEmail,
        preferredFirstName, propWash, raymondJones, state, tpsClass, track, userStatus,
        workEmail, workPhone, zip } = data

    return (
        <Box style={{marginTop: '4rem'}}>
            <Grid container style={{ margin: "2rem", textAlign: "left", lineHeight: "1.5rem" }}>
                <Grid item>
                    {lastName === "" ? null :
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h4">
                                {preferredFirstName === "" ?
                                    `${militaryRank} ${givenFirstName} ${lastName}`
                                    : `${militaryRank} ${preferredFirstName} ${lastName}`}
                            </Typography>
                            {callsign !== "" ?
                                <Typography variant="h5">
                                    Callsign: {callsign}
                                </Typography>
                                : null}
                        </Paper>
                    }
                    {address !== "" || personalCell !== "" || personalEmail !== "" | workPhone !== "" || workEmail !== "" ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            {address !== "" ?
                                <Typography variant="h5">
                                    Address: {address + ","} {city + ","} {state} {zip}
                                </Typography>
                                : null}
                            {personalCell !== "" ?
                                <Typography variant="h5">
                                    Personal Cell: {personalCell}
                                </Typography>
                                : null}
                            {personalEmail !== "" ?
                                <Typography variant="h5">
                                    Personal E-mail: {personalEmail}
                                </Typography>
                                : null}
                            {workPhone !== "" ?
                                <Typography variant="h5">
                                    Work Phone: {workPhone}
                                </Typography>
                                : null}
                            {workEmail !== "" ?
                                <Typography variant="h5">
                                    Work E-mail: {workEmail}
                                </Typography>
                                : null}

                        </Paper>
                        : null}
                    {currentJobTitle !== "" || currentCompany !== "" ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            {currentJobTitle !== "" ?
                                <Typography variant="h5">
                                    Current Job Title: {currentJobTitle}
                                </Typography>
                                : null}
                            {currentCompany !== "" ?
                                <Typography variant="h5">
                                    Current Company: {currentCompany}
                                </Typography>
                                : null}
                        </Paper>
                        : null}
                </Grid>
                <Grid item>
                    {gradNumber !== "" || tpsClass !== "" || track !== "" ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            {gradNumber !== "" ?
                                <Typography variant="h5">
                                    Grad Number: {gradNumber}
                                </Typography>
                                : null}
                            {tpsClass !== "" ?
                                <Typography variant="h5">
                                    TPS Class: {tpsClass}
                                </Typography>
                                : null}
                            {track !== "" ?
                                <Typography variant="h5">
                                    TPS Student Track: {track}
                                </Typography>
                                : null}
                        </Paper>
                        : null}
                    {militaryService !== "" || userStatus !== "" ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            {militaryService !== "" ?
                                <Typography variant="h5">
                                    Military Service: {militaryService}
                                </Typography>
                                : null}
                            {userStatus !== "" ?
                                <Typography variant="h5">
                                    Military Status: {userStatus}
                                </Typography>
                                : null}
                        </Paper>
                        : null}
                    {country !== "" ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                Country: {country}
                            </Typography>
                        </Paper>
                        : null}
                    {foreignOfficer && country !== "" ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                Foreign Officer: {country}
                            </Typography>
                        </Paper>
                        : null}
                    {cDot ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                Aaron C. "C-Dot" George Award
                                </Typography>
                        </Paper>
                        : null}
                    {liethenTittle ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                Liethen-Tittle Award
                                </Typography>
                        </Paper>
                        : null}
                    {propWash ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                Onizuka Prop Wash Award
                                </Typography>
                        </Paper>
                        : null}
                    {raymondJones ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                Raymond Jones Award
                                </Typography>
                        </Paper>
                        : null}
                    {distinguishedAlumnus ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                Distinguished Alumnus Award
                                </Typography>
                        </Paper>
                        : null}
                    {astronaut ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                Astronaut
                                    </Typography>
                        </Paper>
                        : null}
                    {commandant ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                USAF TPS Commandant
                                </Typography>
                        </Paper>
                        : null}
                    {generalOfficer ?
                        <Paper style={{ margin: "1rem", padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }}>
                            <Typography variant="h5">
                                General Officer
                                        </Typography>
                        </Paper>
                        : null}
                </Grid>
            </Grid>
        </Box>
    )
}

export default ShowOneGrad
